'use client';

import React, { useContext } from 'react';
import MapUI from './map-ui';
import ListViewUI from './listview-ui';
import ViewToggle from './view-toggle';
import { StoreLocatorMapContext } from './pharmacy.context';
import clsx from 'clsx';
import { desktopSize, useMediaQuery } from 'hooks/use-media-query';
import MobileCanvas from './mobile-canvas';
import SwitchView from './switcher';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function CanvasWrapper({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { isListView, mapIsOpen, setMapIsOpen } = useContext(
    StoreLocatorMapContext
  );
  const matches = useMediaQuery(desktopSize);
  return (
    <>
      {matches ? (
        <div
          className={clsx(
            'relative h-[600px] overflow-hidden rounded-e-lg lg:col-span-3'
          )}
        >
          <div
            className={clsx(
              'h-full',
              `${
                !isListView
                  ? 'bg-gray-300 lg:h-[600px]'
                  : 'scrollbar-style | overflow-y-auto overflow-x-hidden bg-gray-100 lg:h-[600px]'
              }`
            )}
          >
            <MainView language={language} region={region} />
          </div>
        </div>
      ) : (
        <MobileCanvas
          open={mapIsOpen}
          closeHandler={() => setMapIsOpen?.(false)}
        >
          <MainView language={language} region={region} />
        </MobileCanvas>
      )}
    </>
  );
}

export function MainView({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const {
    isListView,
    setIsListView,
    activeMarker,
    t,
    activeCategoryFilter,
    stores,
  } = useContext(StoreLocatorMapContext);

  const list = activeCategoryFilter
    ? stores.filter(
        (store) =>
          (store as unknown as Record<string, unknown>)?.[
            activeCategoryFilter
          ] == true
      )
    : stores;
  return (
    <>
      {stores.length > 0 && <ViewToggle language={language} region={region} />}
      {isListView ? (
        <ListViewUI language={language} region={region} />
      ) : (
        <MapUI language={language} region={region} />
      )}
      {!activeMarker && list.length > 0 && (
        <div className='-z-1 fixed bottom-8 left-1/2 z-10 block -translate-x-1/2 transform lg:hidden'>
          <SwitchView
            setIsListView={setIsListView}
            isListView={isListView}
            t={t}
          />
        </div>
      )}
    </>
  );
}
