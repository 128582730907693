'use client';

import { StoreLocatorMapContext } from './map';
import MapControls from './map/map-controls';
import { useLocator } from './map/hooks/use-locator';
import CanvasWrapper from './map/canvas-wrapper';

import { LanguageCodesType, RegionCodesType } from 'utils';

export default function PharmacyLocatorWrapper({
  children,
  language,
  region,
}: {
  children: JSX.Element | JSX.Element[];
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const {
    t,
    ts,
    markers,
    setMarkers,
    map,
    setMap,
    cordinate,
    setCordinate,
    activeMarker,
    setActiveMarker,
    containerStyle,
    isUnknownLocation,
    setIsUnknownLocation,
    cities,
    fetchPharmacyByDistricts,
    stores,
    selectedStore,
    setSelectedStore,
    fetchByCordinate,
    isListView,
    setIsListView,
    isLoading,
    mapIsOpen,
    setMapIsOpen,
    categories,
    activeCategoryFilter,
    setActiveCategoryFilter,
    mode,
    rangeOptions,
  } = useLocator(language, region);

  return (
    <StoreLocatorMapContext.Provider
      value={{
        t,
        ts,
        markers,
        setMarkers,
        containerStyle,
        map,
        setMap,
        activeMarker,
        setActiveMarker,
        cordinate,
        setCordinate,
        isUnknownLocation,
        setIsUnknownLocation,
        cities,
        fetchPharmacyByDistricts,
        stores,
        selectedStore,
        setSelectedStore,
        fetchByCordinate,
        isListView,
        setIsListView,
        isLoading,
        mapIsOpen,
        setMapIsOpen,
        categories,
        activeCategoryFilter,
        setActiveCategoryFilter,
        mode,
        rangeOptions,
      }}
    >
      {children}
    </StoreLocatorMapContext.Provider>
  );
}

PharmacyLocatorWrapper.CanvasWrapper = CanvasWrapper;
PharmacyLocatorWrapper.MapControls = MapControls;
