'use client';

import clsx from 'clsx';
import {
  CheckIcon,
  ChevronDownIcon,
  NotAvailableStoreLocationIcon,
  SearchIcon,
  StoreLocationIcon,
} from 'components/icon';
import React, { useContext, useState } from 'react';
import { getTextByLang, LanguageCodesType, RegionCodesType } from 'utils';
import { StoreLocatorMapContext } from './pharmacy.context';
import { Select } from 'components/select';
import { useMediaQuery, desktopSize } from 'hooks/use-media-query';
import { twMerge } from 'tailwind-merge';
import SecondaryOptionModal from 'components/secondary-option-modal';
import SwitchView from './switcher';

export default function ViewToggle({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t, categories, isListView, setIsListView } = useContext(
    StoreLocatorMapContext
  );
  const matches = useMediaQuery(desktopSize);

  return (
    <div
      className={clsx(
        'top-0 flex h-14 w-full items-center justify-between',
        'z-10 bg-yellow/35 ltr:pl-6 ltr:pr-3 rtl:pl-3 rtl:pr-6',
        'lg:flex-row-reverse',
        `${isListView ? 'sticky' : 'absolute'}`
      )}
    >
      <div className='filter'>
        {matches ? (
          <div className='flex items-center gap-2'>
            <ViewWeb language={language} region={region} />
            <SwitchView
              setIsListView={setIsListView}
              isListView={isListView}
              t={t}
            />
          </div>
        ) : (
          categories.length > 0 && (
            <ViewMobile language={language} region={region} />
          )
        )}
      </div>
      <div className='flex items-center space-x-4 rtl:space-x-reverse'>
        <div className='flex items-center space-x-1 rtl:space-x-reverse'>
          <StoreLocationIcon />
          <span className='text-custom-xs font-semibold text-gray-dark'>
            {t?.('open')}
          </span>
        </div>
        <div className='flex items-center space-x-1 rtl:space-x-reverse'>
          <NotAvailableStoreLocationIcon />
          <span className='text-custom-xs font-semibold text-gray-dark'>
            {t?.('closed')}
          </span>
        </div>
      </div>
    </div>
  );
}

export function ViewWeb({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t, categories, setActiveCategoryFilter, activeCategoryFilter } =
    useContext(StoreLocatorMapContext);
  const setSelected = (filter: string) => {
    const categoryKey = categories.find(
      (category) => getTextByLang(category.value, language) == filter
    )?.key;
    if (activeCategoryFilter == filter) {
      setActiveCategoryFilter?.(undefined);
    } else {
      setActiveCategoryFilter?.(categoryKey);
    }
  };

  return (
    <Select
      data-testid='categories'
      name='categories'
      id='categories'
      options={categories.map((service) => {
        return getTextByLang(service.value, language);
      })}
      defaultValue={t?.('filter_by_services')}
      onSelectedOptionChange={setSelected}
      optionSearch
      optionCheckIcon
      containerClassName='h-[40px] min-w-[250px]'
      className='flex h-full items-center border-light-gray text-custom-base text-gray-500'
      arrowClassName='text-gray-500 -translate-y-1/2 translate-x-[-50%] top-[50%]'
      optionClassName='text-gray-500 text-custom-base hover:text-blue hover:bg-transparent hover:font-semibold ltr:text-left rtl:text-right py-4 px-0'
      optionParentClassName='top-16 rounded-md px-4 border-transparent shadow-lg max-h-[300px]'
      language={language}
      region={region}
    />
  );
}

export function ViewMobile({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { t, categories, activeCategoryFilter, setActiveCategoryFilter } =
    useContext(StoreLocatorMapContext);
  const [filter, setFilter] = useState(categories);

  const handleFilterChange = (query: string) => {
    const matchingfields = categories.filter((cat) =>
      getTextByLang(cat.value, language).toLowerCase().includes(query)
    );
    setFilter(matchingfields);
  };

  const setSelected = (filter: string) => {
    if (activeCategoryFilter == filter) {
      setActiveCategoryFilter?.(undefined);
    } else {
      setActiveCategoryFilter?.(filter);
    }
    setIsOpen(false);
  };

  const activeFilterData = categories.find(
    (val) => val.key == activeCategoryFilter
  );

  const data = activeFilterData
    ? getTextByLang(activeFilterData.value, language)
    : t?.('filter_by_services');

  return (
    <>
      <button
        onClick={() => setIsOpen((d) => !d)}
        className='relative flex h-[40px] min-w-[190px] items-center justify-start overflow-hidden rounded-lg border border-[#bdbdbd] bg-white px-2 py-2 outline-none'
      >
        <span className='line-clamp-1 text-gray-400'>{data}</span>
        <ChevronDownIcon
          className={twMerge(
            `pointer-events-none absolute bottom-2 text-blue transition-all duration-200 ease-linear ltr:right-1 rtl:left-6 ${
              isOpen ? 'rotate-180' : 'rotate-0'
            }`,
            'top-[50%] -translate-y-1/2 translate-x-[-50%] text-gray-400'
          )}
        />
      </button>

      <SecondaryOptionModal
        modalId='switch-method-modal'
        open={isOpen}
        setOpen={(v: boolean) => setIsOpen(v)}
        title={t?.('select_services')}
        contentClassName='bg-white font-montserrat'
        reactPortalRemove
        dynamicModalCloseHandler={() => setIsOpen(false)}
        language={language}
        region={region}
      >
        <div className='min-h-[400px]'>
          <div className='relative mb-6 mt-4 h-10'>
            <SearchIcon className='has-color absolute bottom-2.5 fill-current text-light-gray ltr:left-3 rtl:right-3' />
            <input
              type='text'
              onChange={(e) => handleFilterChange(e.target.value.toLowerCase())}
              placeholder={t?.('Search')}
              className={clsx(
                'h-full w-full rounded-lg border border-light-gray',
                'text-custom-base placeholder:text-light-gray ltr:pl-[44px] rtl:pr-[44px]',
                'focus:border-transparent focus:ring-0',
                'js-search-input'
              )}
            />
          </div>

          {filter.length > 0 ? (
            <ul>
              {filter.map((d) => (
                <li
                  onClick={() => setSelected(d.key)}
                  className='flex items-center justify-between border-b border-light-gray-200 py-4 font-montserrat'
                  key={d.key}
                >
                  <span>{getTextByLang(d.value, language)}</span>
                  <CheckIcon
                    className={`${
                      d.key === activeCategoryFilter ? 'visible' : 'invisible'
                    } group-hover:visible`}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <div className='mt-28 text-center'>{t?.('no_data')}</div>
          )}
        </div>
      </SecondaryOptionModal>
    </>
  );
}
