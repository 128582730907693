'use client';

import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CheckIcon, SearchIcon } from './icon';
import MobileModal from './mobile-modal';
import { MobileSelectModalType } from 'types/select';

export default function MobileSelectModal({
  mobileSelectModalOpen,
  setMobileSelectModalOpen,
  options,
  mobileActiveOption,
  handleMobileOptionClick,
  title,
  optionSearch,
  containerClassName,
}: MobileSelectModalType) {
  const [filter, setFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [dymamicOptions, setDynamicOptions] = useState(options);
  const handleFilterChange = (e: any) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);

    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value)
    );
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    setDynamicOptions(optionSearch ? filteredOptions : options);
  }, [dymamicOptions, filteredOptions, optionSearch, options]);
  // just work when optionSearch is true

  useEffect(() => {
    if (!options) return;
    setFilteredOptions(options);
    setDynamicOptions(options);
  }, [options]);

  return (
    <MobileModal
      isOpen={mobileSelectModalOpen}
      setIsOpen={setMobileSelectModalOpen}
      title={title}
      style='bottom'
      containerClassName={containerClassName}
    >
      <ul className='divide-y divide-light-gray-100'>
        {optionSearch && (
          <li className='mt-6'>
            <div className='relative mb-6 h-10'>
              <SearchIcon className='has-color absolute bottom-2.5 fill-current text-light-gray ltr:left-3 rtl:right-3' />
              <input
                type='text'
                value={filter}
                onChange={handleFilterChange}
                placeholder='Search'
                className={clsx(
                  'h-full w-full rounded-lg border border-light-gray',
                  'text-custom-base placeholder:text-light-gray ltr:pl-[44px] rtl:pr-[44px]',
                  'focus:border-transparent focus:ring-0',
                  'js-search-input'
                )}
              />
            </div>
          </li>
        )}

        {dymamicOptions.map((option, index) => (
          <li
            key={`option-key-${index}`}
            onClick={() => handleMobileOptionClick(option)}
            className={clsx(
              'flex items-center justify-between py-4 text-custom-base',
              'hover:font-semibold hover:text-blue',
              `${
                mobileActiveOption === option ? 'font-semibold text-blue' : ''
              }`
            )}
          >
            <span>{option}</span>
            {mobileActiveOption === option && <CheckIcon />}
          </li>
        ))}
      </ul>
    </MobileModal>
  );
}
