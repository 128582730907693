'use client';

import { forwardRef, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { CheckIcon, ChevronDownIcon, SearchIcon } from './icon';
import { SelectType } from 'types/select';
import Input from './input/input';
import { useTranslation } from 'app/i18n/client';
import useScrollUp from 'hooks/use-scroll-up';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

const Select = forwardRef<HTMLSelectElement, SelectType>((props, ref) => {
  const {
    name,
    className,
    id,
    options,
    defaultValue,
    arrowClassName,
    optionClassName,
    optionParentClassName,
    containerClassName,
    iconHtml,
    label,
    labelClassName,
    isFormik,
    variant,
    optionSearch,
    optionCheckIcon,
    onSelectedOptionChange,
    wrapperCustomStyle,
    dropdownCustomStyle,
    arrowHtml,
    onSelectClick: onSelectClickProp,
    disableOpenOnClick,
    placeholder,
    displayAlways,
    language,
    region,
    ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const refOptions = useRef(null);
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );
  // just work when optionSearch is true
  const [filter, setFilter] = useState('');
  useEffect(() => {
    if (
      (options && options.length > 0 && !selectedValue) ||
      !options?.some((option) => option === selectedValue)
    ) {
      setSelectedValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const onScrollUp = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useScrollUp(onScrollUp);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  function handleSelectChange(event: any) {
    if (event.target.value == selectedValue) {
      setSelectedValue(defaultValue);
      return;
    }
    setSelectedValue(event.target.value);
    setIsOpen(false);

    if (onSelectedOptionChange) {
      const newValue = onSelectedOptionChange(event.target.value);
      if (newValue) {
        setSelectedValue(newValue);
      }
    }
  }

  // just work when optionSearch is true
  const handleFilterChange = (e: any) => {
    setFilter(e.target.value.toLowerCase());
  };
  // just work when optionSearch is true

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeOpenMenu = (event: any) => {
    // just work when optionSearch is true
    if (!event.target.classList.contains('js-search-input')) {
      if (
        refOptions.current &&
        isOpen &&
        !(refOptions.current as HTMLElement).contains(event.target)
      ) {
        setIsOpen(false);
        // just work when optionSearch is true
        setFilter('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeOpenMenu);
  }, [closeOpenMenu]);

  const onSelectClick = () => {
    if (onSelectClickProp) {
      onSelectClickProp(!isOpen);
    }

    if (!disableOpenOnClick) {
      setIsOpen(!isOpen);
    }
  };

  if (!options?.length && !displayAlways) {
    return null;
  }

  return (
    <div className={twMerge('relative', containerClassName)}>
      {label && (
        <label
          htmlFor={id}
          className={twMerge(
            'absolute top-2.5 !text-custom-xs text-gray ltr:pl-3 rtl:pr-3',

            labelClassName
          )}
        >
          {label}
        </label>
      )}
      {iconHtml}
      <div
        ref={refOptions}
        onClick={() => onSelectClick()}
        className={twMerge(
          clsx(
            'border-light-gray-300 bg-white',
            'cursor-pointer border text-custom-lg text-blue',
            'focus:border-light-gray-400 appearance-none overflow-clip whitespace-nowrap py-1 focus:outline-none focus:ring-0',
            `${
              iconHtml
                ? 'ltr:pl-10 rtl:pr-10'
                : 'ltr:pl-3 ltr:pr-8 rtl:pl-8 rtl:pr-3'
            }`,
            `${
              isOpen
                ? 'rounded-tl-md rounded-tr-md border-b-transparent'
                : 'rounded-md'
            }`
          ),
          className
        )}
        style={wrapperCustomStyle || {}}
      >
        {selectedValue ? (
          selectedValue
        ) : (
          <span className='text-gray'>
            {placeholder || t('select_placeholder')}
          </span>
        )}
      </div>

      <div
        className={twMerge(
          clsx(
            'absolute z-[52] w-full divide-y bg-white',
            'divide-light-gray-300 rounded-bl-md rounded-br-md border border-light-gray-300',
            'max-h-[400px] overflow-y-auto [&>*:last-child]:rounded-bl-md [&>*:last-child]:rounded-br-md',
            `${isOpen ? 'block' : 'hidden'}`
          ),
          optionParentClassName
        )}
        style={dropdownCustomStyle || {}}
      >
        {optionSearch && (
          <div className='relative mb-6 h-10'>
            <SearchIcon className='has-color absolute bottom-2.5 fill-current text-light-gray ltr:left-3 rtl:right-3' />
            <input
              type='text'
              value={filter}
              onChange={handleFilterChange}
              placeholder='Search'
              className={clsx(
                'h-full w-full rounded-lg border border-light-gray',
                'text-custom-base placeholder:text-light-gray ltr:pl-[44px] rtl:pr-[44px]',
                'focus:border-transparent focus:ring-0',
                'js-search-input'
              )}
              style={dropdownCustomStyle || {}}
            />
          </div>
        )}
        {options
          ?.filter((option) => option?.toLowerCase()?.includes(filter))
          ?.map((option, index) => (
            <div
              key={index}
              className='group flex items-center justify-between'
            >
              <input
                onClick={(event) => handleSelectChange(event)}
                name={name}
                className={twMerge(
                  clsx(
                    'w-full cursor-pointer py-2 text-center text-custom-base',
                    'text-black hover:bg-blue hover:text-white',
                    'focus:border-light-gray-400 focus:outline-none focus:ring-0'
                  ),
                  optionClassName
                )}
                value={option}
                readOnly={true}
              />
              {optionCheckIcon && (
                <CheckIcon
                  className={`${
                    selectedValue === option ? 'visible' : 'invisible'
                  } group-hover:visible`}
                />
              )}
            </div>
          ))}
        {/* just work when optionSearch is true */}
        {options.filter((option) => option?.toLowerCase()?.includes(filter))
          .length === 0 && (
          <p className='py-2 text-center text-custom-base text-gray'>
            {t('no_results')}
          </p>
        )}
      </div>

      {arrowHtml ? (
        arrowHtml
      ) : (
        <ChevronDownIcon
          className={twMerge(
            `pointer-events-none absolute bottom-2 text-blue ltr:right-1 rtl:left-1 ${
              isOpen ? 'rotate-180' : 'rotate-0'
            }`,
            arrowClassName
          )}
        />
      )}

      {isFormik ? (
        <>
          <Input
            as='select'
            name={name}
            label={label}
            value={selectedValue}
            variant={variant}
            containerClassname='hidden'
            language={language}
            region={region}
          />
        </>
      ) : (
        <select
          {...rest}
          name={name}
          ref={ref}
          defaultValue={selectedValue}
          className='hidden'
        >
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
    </div>
  );
});

Select.displayName = 'Select';

export { Select };
