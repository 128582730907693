'use client';

import { forwardRef, useRef } from 'react';
import { Field } from 'formik';
import { CheckIcon } from 'components/icons';
import { RadioType } from 'types/radio';
import Image from 'next/image';

const Radio = forwardRef<HTMLInputElement, RadioType>((props, refInput) => {
  const {
    name,
    isFormik,
    label,
    value,
    checked,
    disabled,
    variant,
    errorMessages,
    externalicon,
  } = props;
  refInput = useRef(null);
  return (
    <div className='relative h-5'>
      {isFormik ? (
        <label className='flex items-center space-x-1 rtl:space-x-reverse'>
          <Field
            type='radio'
            name={name}
            value={value}
            checked={checked}
            disabled={disabled}
            className='custom-radio h-5 w-5 disabled:cursor-not-allowed disabled:opacity-50'
          />
          {label && <span className='text-custom-sm'>{label}</span>}
        </label>
      ) : (
        <input
          ref={refInput}
          name={name}
          type='radio'
          className='custom-radio h-5 w-5 disabled:cursor-not-allowed disabled:opacity-50'
          {...props}
        />
      )}
      {externalicon ? (
        checked && (
          <Image
            src={externalicon}
            alt='External Icon'
            height={20}
            width={20}
            className='pointer-events-none absolute inset-0 h-5 w-5'
          />
        )
      ) : (
        <CheckIcon className='pointer-events-none absolute left-1 top-[6px]' />
      )}

      {variant === 'error' && (
        <span className='text-custom-xs text-error'>{errorMessages}</span>
      )}
    </div>
  );
});
Radio.displayName = 'Radio';
export { Radio };
