import { useState, useEffect } from 'react';

const useScrollUp = (callback: () => void) => {
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY === 0) return;

      if (currentScrollY < lastScrollY) {
        callback();
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, callback]);

  return;
};

export default useScrollUp;
