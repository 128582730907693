import { Button } from 'components/buttons/button';
import { Radio } from 'components/radio';
import { Select } from 'components/select';
import React, { useContext, useEffect, useState } from 'react';
import { StoreLocatorMapContext } from './pharmacy.context';
import { getTextByLang } from 'utils';
import { DistrictType } from 'types/country';
import LoadingIndicator from 'components/shipping-method-switcher/maps/map-loader';
import { FinderMode } from '../enum';
import { LanguageCodesType, RegionCodesType } from 'utils';
import MobileSelectModal from 'components/mobile-select-modal';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';

export default function MapControls({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { fetchByCordinate, setCordinate, t, isLoading, mode, rangeOptions } =
    useContext(StoreLocatorMapContext);

  const [selRadius, setSelectedRadius] = useState<string | undefined>();
  const [isCtrlsEnabled, setIsCtrlsEnabled] = useState(true);

  useEffect(() => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((permissionStatus) => {
        if (permissionStatus.state === 'denied') {
          setIsCtrlsEnabled(false);
        }
        permissionStatus.onchange = () => {
          if (permissionStatus.state === 'granted') {
            setIsCtrlsEnabled(true);
          }
        };
      });
  }, []);

  const pickLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCordinate?.({
            lat: position?.coords?.latitude ?? 0,
            lng: position.coords.longitude ?? 0,
          });
          fetchByCordinate?.(
            position?.coords?.latitude ?? 0,
            position.coords.longitude ?? 0,
            selRadius
          );
        },
        () => {
          setIsCtrlsEnabled(false);
        }
      );
    }
  };
  return (
    <div className='mt-4 w-full bg-white p-4 lg:mt-0 ltr:font-montserrat lg:ltr:rounded-tl-lg rtl:font-tajawal lg:rtl:rounded-tr-lg'>
      <h2 className='hidden text-xl lg:block'>{t?.('near_by')}</h2>
      <div className='mt-4'>
        <span className='text-custom-base text-gray-dark/90 ltr:font-montserrat rtl:font-tajawal'>
          {t?.('find_closest')}
        </span>
        <div className='mt-4 flex items-center space-x-4 rtl:space-x-reverse'>
          {rangeOptions.map((option, index) => (
            <div
              className='flex cursor-pointer items-center space-x-1 rtl:space-x-reverse'
              key={`distince-option-${index}`}
            >
              <Radio
                disabled={!isCtrlsEnabled}
                name='distance'
                id={'distance-' + index}
                onChange={() => {
                  setSelectedRadius(option.toString());
                }}
              />
              <label
                htmlFor={'distance-' + index}
                className='text-custom-xs font-semibold text-gray-dark'
              >
                {t?.('km', { distance: option })}
              </label>
            </div>
          ))}
        </div>
        <Button
          disabled={isLoading || !selRadius || !isCtrlsEnabled}
          onClick={pickLocation}
          className='mt-4 h-10 py-0 !text-custom-xs ltr:font-montserrat rtl:font-tajawal'
        >
          {t?.('find_your_location')}
          {isLoading && mode == FinderMode.PROXIMITY && <LoadingIndicator />}
        </Button>
        <div className='my-6 flex items-center'>
          <div className='w-1/2 border border-light-gray-300 lg:w-[5%]'></div>
          <span className='px-1 text-[9px] font-bold uppercase text-light-gray'>
            {t?.('or')}
          </span>
          <div className='w-1/2 border border-light-gray-300 lg:w-full'></div>
        </div>
        <MapSearch language={language} region={region} />
      </div>
    </div>
  );
}

export function MapSearch({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t, cities, fetchPharmacyByDistricts, setCordinate, isLoading, mode } =
    useContext(StoreLocatorMapContext);
  const [districts, setDistricts] = useState<DistrictType[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const isMobileSize = useMediaQuery(mobileSize);
  const [cityMobileModalOpen, setCityMobileModalOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [districtMobileModalOpen, setDistrictMobileModalOpen] = useState(false);

  const getPharmaciesByDistrict = () => {
    if (selectedDistrict.trim() != '') {
      const district = districts.find(
        (district) => district.id === selectedDistrict
      );
      setCordinate?.({
        lat: district?.latitude ?? 0,
        lng: district?.longitude ?? 0,
      });
      fetchPharmacyByDistricts?.(selectedDistrict ?? '');
    }
  };

  const handleCitySelect = (option: string) => {
    setSelectedCity(option);
    const cityDistricts =
      cities.find((country) => getTextByLang(country.name, language) == option)
        ?.districts ?? [];
    setDistricts(cityDistricts);
    setSelectedDistrict('');
    setCityMobileModalOpen(false);
  };

  const handleDistrictSelect = (option: string) => {
    const district = districts.find(
      (district) => getTextByLang(district.name, language) === option
    );
    setSelectedDistrict(district?.id ?? '');
    setDistrictMobileModalOpen(false);
  };

  const districtsOptions =
    districts?.map((district) => {
      return getTextByLang(district.name, language);
    }) || [];

  const citiesOptions =
    cities?.map?.((city) => {
      return getTextByLang(city.name, language);
    }) || [];

  const selectedDistrictData = districts?.find(
    (district) => district.id === selectedDistrict
  );

  const selectedDistrictName =
    selectedDistrict &&
    getTextByLang(selectedDistrictData!.name || '', language);

  return (
    <div>
      <h3 className='mb-4 text-custom-base text-gray-dark/90 ltr:font-montserrat rtl:font-tajawal'>
        {t?.('manual_search')}
      </h3>
      <div className='space-y-4'>
        <div onClick={() => isMobileSize && setCityMobileModalOpen(true)}>
          <Select
            data-testid='city'
            name='city'
            id='city-product-availability'
            options={citiesOptions}
            placeholder={t?.('select_city')}
            defaultValue={selectedCity}
            onSelectedOptionChange={handleCitySelect}
            disableOpenOnClick={isMobileSize}
            optionSearch
            optionCheckIcon
            containerClassName='h-[52px]'
            className='flex h-full items-center border-light-gray text-custom-base text-gray-500'
            arrowClassName='text-gray-500 -translate-y-1/2 translate-x-[-50%] top-[50%]'
            optionClassName='text-gray-500 text-custom-base hover:text-blue hover:bg-transparent hover:font-semibold ltr:text-left rtl:text-right py-4 px-0'
            optionParentClassName='top-16 rounded-md px-4 border-transparent shadow-lg max-h-[300px]'
            language={language}
            region={region}
          />
        </div>
        {isMobileSize && (
          <MobileSelectModal
            mobileSelectModalOpen={cityMobileModalOpen}
            setMobileSelectModalOpen={setCityMobileModalOpen}
            options={citiesOptions}
            mobileActiveOption={selectedCity}
            handleMobileOptionClick={handleCitySelect}
            title={t?.('select_city')}
            optionSearch
            containerClassName='h-full'
          />
        )}
        <div onClick={() => isMobileSize && setDistrictMobileModalOpen(true)}>
          <Select
            data-testid='districts'
            name='districts'
            id='districts'
            options={districtsOptions}
            placeholder={t?.('select_district')}
            defaultValue={selectedDistrictName}
            disableOpenOnClick={isMobileSize}
            onSelectedOptionChange={handleDistrictSelect}
            optionSearch
            optionCheckIcon
            containerClassName='h-[52px]'
            className='flex h-full items-center border-light-gray text-custom-base text-gray-500'
            arrowClassName='text-gray-500 -translate-y-1/2 translate-x-[-50%] top-[50%]'
            optionClassName='text-gray-500 text-custom-base hover:text-blue hover:bg-transparent hover:font-semibold ltr:text-left rtl:text-right py-4 px-0'
            optionParentClassName='top-16 rounded-md px-4 border-transparent shadow-lg max-h-[300px]'
            language={language}
            region={region}
          />
        </div>
        {isMobileSize && (
          <MobileSelectModal
            mobileSelectModalOpen={districtMobileModalOpen}
            setMobileSelectModalOpen={setDistrictMobileModalOpen}
            options={districtsOptions}
            mobileActiveOption={selectedDistrict}
            handleMobileOptionClick={handleDistrictSelect}
            title={t?.('select_district')}
            optionSearch
            containerClassName='h-full'
          />
        )}
        <Button
          disabled={isLoading || districts.length == 0 || !selectedDistrict}
          className='mt-4 h-10 py-0 !text-custom-xs ltr:font-montserrat rtl:font-tajawal'
          onClick={getPharmaciesByDistrict}
        >
          {t?.('search')}
          {isLoading && mode == FinderMode.SEARCH && <LoadingIndicator />}
        </Button>
      </div>
    </div>
  );
}
