import { Button } from 'components/buttons/button';
import React, { useContext } from 'react';
import { StoreLocatorMapContext } from './pharmacy.context';
import StoreDetailCard from './card';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function ListViewUI({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t, stores, activeCategoryFilter } = useContext(
    StoreLocatorMapContext
  );
  const list = activeCategoryFilter
    ? stores.filter(
        (store) =>
          (store as unknown as Record<string, unknown>)?.[
            activeCategoryFilter
          ] == true
      )
    : stores;
  return (
    <div className='space-y-4 p-4 lg:mx-0'>
      {list?.map((storeItem, index) => (
        <div
          key={`store-list-item-${index}`}
          className='flex flex-col justify-between gap-4 rounded-lg bg-white p-4 shadow-md lg:flex-row'
        >
          <div className='md:max-w-[70%]'>
            <StoreDetailCard
              t={t}
              storeItem={storeItem}
              index={index}
              language={language}
              region={region}
            />
          </div>

          <Button
            className='h-10 self-end py-0 !text-custom-xs lg:w-[180px] ltr:font-montserrat rtl:font-tajawal'
            onClick={() => window.open(storeItem.google_maps_url, '_blank')}
          >
            {t?.('get_directions')}
          </Button>
        </div>
      ))}
    </div>
  );
}
