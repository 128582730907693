import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 *
 * @param input string | Array | object
 * @returns rationally returns a string of classes without conflict
 */
export const cn = (...input: ClassValue[]) => {
  return twMerge(clsx(input));
};
