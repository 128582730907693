import { DEFAULT_GEO } from 'components/shipping-method-switcher/maps/utils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'app/i18n/client';
import { INearByStore } from 'types/shipping-method-switcher';
import { IMarkerData, IMapCordinate } from '../pharmacy.context';
import {
  getCountries,
  getNearbyPharmacies,
  getStoresWithinDistrict,
} from 'lib/googlemap';
import { CountryType } from 'types/country';
import { getAppSettings } from 'lib/cms/nahdi-middleware/app-settings';
import { FinderMode } from 'components/pharmacy-locator/enum';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export function useLocator(
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'store-finder'
  );
  const [markers, setMarkers] = useState<IMarkerData[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [cities, setCities] = useState<CountryType[]>([]);
  const [cordinate, setCordinate] = useState<IMapCordinate>(DEFAULT_GEO);
  const [activeMarker, setActiveMarker] = useState<google.maps.LatLng | null>(
    null
  );
  const [stores, setStores] = useState<INearByStore[]>([]);
  const [rangeOptions, setRangeOptions] = useState<number[]>([]);
  const [containerStyle] = useState<React.CSSProperties>({
    width: '100%',
    height: '100%',
  });
  const [isUnknownLocation, setIsUnknownLocation] = useState(false);
  const [selectedStore, setSelectedStore] = useState<INearByStore | undefined>(
    undefined
  );
  const [isListView, setIsListView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mapIsOpen, setMapIsOpen] = useState(false);
  const [categories, setCategories] = useState<
    { key: string; value: { en: string; ar: string } }[]
  >([]);
  const [activeCategoryFilter, setActiveCategoryFilter] = useState<
    string | undefined
  >();
  const [mode, setMode] = useState<FinderMode>(FinderMode.INITIAL);

  useEffect(() => {
    const fetchCitiesAndDistricts = async () => {
      const [settings, cities] = await Promise.all([
        getAppSettings(language, region),
        getCountries(language, region),
      ]);
      setCategories(settings.global_components?.store_locator?.services ?? []);
      setRangeOptions(settings.global_components.range_values ?? []);
      setCities(cities);
    };
    fetchCitiesAndDistricts();
  }, [language, region]);

  const fetchPharmacyByDistricts = useCallback(
    async (districtId: string) => {
      try {
        setIsLoading(true);
        setMode(FinderMode.SEARCH);
        const result = await getStoresWithinDistrict(
          districtId,
          language,
          region
        );
        setStores(result);
        setMapIsOpen(true);
      } catch (ex) {
        setStores([]);
      } finally {
        setIsLoading(false);
        setMode(FinderMode.INITIAL);
      }
    },
    [language, region]
  );

  const fetchByCordinate = useCallback(
    async (lat: number, lng: number, radius?: string) => {
      try {
        setIsLoading(true);
        setMode(FinderMode.PROXIMITY);
        const result = await getNearbyPharmacies(
          lng,
          lat,
          radius,
          language,
          region
        );
        if (Array.isArray(result)) {
          setStores(result);
          setMapIsOpen(true);
        }
      } catch (ex) {
        setStores([]);
      } finally {
        setIsLoading(false);
        setMode(FinderMode.INITIAL);
      }
    },
    [language, region]
  );

  return {
    t,
    ts,
    markers,
    setMarkers,
    map,
    setMap,
    cordinate,
    setCordinate,
    activeMarker,
    setActiveMarker,
    containerStyle,
    isUnknownLocation,
    setIsUnknownLocation,
    cities,
    fetchPharmacyByDistricts,
    stores,
    selectedStore,
    setSelectedStore,
    fetchByCordinate,
    isListView,
    setIsListView,
    isLoading,
    mapIsOpen,
    setMapIsOpen,
    categories,
    activeCategoryFilter,
    setActiveCategoryFilter,
    mode,
    rangeOptions,
  };
}
