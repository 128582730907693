'use client';

import React from 'react';
import PharmacyLocatorWrapper from './index';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function Locator({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  return (
    <PharmacyLocatorWrapper language={language} region={region}>
      <div className='grid w-full grid-cols-1 gap-8 lg:grid-cols-5'>
        <div className='px-4 lg:col-span-2 lg:px-0'>
          <PharmacyLocatorWrapper.MapControls
            language={language}
            region={region}
          />
        </div>
        <PharmacyLocatorWrapper.CanvasWrapper
          language={language}
          region={region}
        />
      </div>
    </PharmacyLocatorWrapper>
  );
}
