import clsx from 'clsx';
import Available from 'components/available';
import { MiniLogoIcon, VerifyIcon, CircleCloseIcon } from 'components/icon';

import React from 'react';
import { INearByStore } from 'types/shipping-method-switcher';
import { getTextByLang, LanguageCodesType, RegionCodesType } from 'utils';

export default function StoreDetailCard({
  storeItem,
  t,
  index,
  language,
  region,
}: {
  index: number;
  storeItem: INearByStore;
  // eslint-disable-next-line @typescript-eslint/ban-types
  t?: Function | undefined;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  return (
    <div className='flex gap-3'>
      <div className='flex h-10 w-10 items-center justify-center self-start rounded-full bg-light-gray-200'>
        <MiniLogoIcon />
      </div>
      <div className='flex w-full flex-col items-start'>
        <div className='flex gap-2 rtl:space-x-reverse'>
          <div
            className={clsx(
              'flex items-center space-x-1 rounded-lg rtl:space-x-reverse',
              storeItem.is_open
                ? 'bg-primary-green-50 text-green'
                : 'bg-error/10 text-error',
              'px-2 py-1 text-custom-xs font-bold'
            )}
          >
            {storeItem.is_open ? <VerifyIcon /> : <CircleCloseIcon />}
            <span>{t?.(storeItem.is_open ? 'open' : 'closed')}</span>
          </div>
          {storeItem.distance && (
            <Available backgroundColor='bg-blue/10'>
              <span className='text-custom-xs font-bold text-blue'>
                {t?.('km', {
                  distance: parseFloat(storeItem.distance).toFixed(1),
                })}
              </span>
            </Available>
          )}
          {index === 0 && (
            <Available backgroundColor='bg-blue/10'>
              <span className='text-custom-xs font-bold text-blue/80 ltr:font-montserrat rtl:font-tajawal'>
                {t?.('nearest_to_you')}
              </span>
            </Available>
          )}
        </div>
        <span className='my-3 !line-clamp-2 block text-custom-base text-gray-dark/70 ltr:!font-montserrat rtl:!font-tajawal'>
          {`${getTextByLang(storeItem?.street, language)} ${getTextByLang(
            storeItem.city,
            language
          )}`}
        </span>

        <div className='flex items-center space-x-1 text-custom-base ltr:!font-montserrat rtl:flex-row-reverse rtl:space-x-reverse rtl:!font-tajawal'>
          <span className='text-gray-dark'>{t?.('phone')}:</span>
          <span className='text-blue/80'>{storeItem?.telephone}</span>
        </div>
        <div className='flex items-center space-x-1 text-custom-base ltr:!font-montserrat rtl:flex-row-reverse rtl:space-x-reverse rtl:!font-tajawal'>
          <span className='text-gray-dark'>{t?.('whatsapp')}:</span>
          <span
            style={{ fontFamily: 'var(--montserrat-font) !important' }}
            className='text-blue/80'
          >
            {storeItem?.whatsapp}
          </span>
        </div>
        <div className='mt-4 !line-clamp-2 text-[13px] text-[#757575] ltr:font-montserrat rtl:font-tajawal'>
          {`${getTextByLang(storeItem?.hours, language)}`}
        </div>
      </div>
    </div>
  );
}
