import { Button } from 'components/buttons/button';
import { DualWindowIcon, MapViewIcon } from 'components/icon';
import React from 'react';

export default function SwitchView({
  setIsListView,
  isListView,
  t,
}: {
  setIsListView: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  isListView: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  t: Function | undefined;
}) {
  return (
    <Button
      className='h-8 bg-blue/80 py-0'
      onClick={() => setIsListView?.((d) => !d)}
    >
      <div className='flex items-center space-x-2'>
        {!isListView ? (
          <DualWindowIcon className='has-color fill-current text-white' />
        ) : (
          <MapViewIcon />
        )}

        <span className='text-custom-xs font-semibold'>
          {!isListView ? t?.('switch_to_list') : t?.('switch_to_map')}
        </span>
      </div>
    </Button>
  );
}
