import clsx from 'clsx';
import { GoBackIcon } from 'components/icon';
import React, { useContext, useEffect } from 'react';
import { StoreLocatorMapContext } from './pharmacy.context';

interface MobileProps {
  open: boolean;
  children: JSX.Element | JSX.Element[];
  closeHandler: (v: boolean) => void;
}

export default function MobileCanvas({
  open,
  closeHandler,
  children,
}: MobileProps) {
  const { stores, activeCategoryFilter, t } = useContext(
    StoreLocatorMapContext
  );
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [open]);
  const list = activeCategoryFilter
    ? stores.filter(
        (store) =>
          (store as unknown as Record<string, unknown>)?.[
            activeCategoryFilter
          ] == true
      )
    : stores;
  return (
    <div
      className={clsx(
        'fixed bottom-0 z-[999] flex h-screen w-full flex-col overflow-y-auto bg-light-gray-200 transition-all duration-500',
        `${
          open
            ? 'ltr:right-0 rtl:left-0'
            : 'ltr:-right-[4000px] rtl:-left-[4000px]'
        }`
      )}
    >
      <div className='bg-white p-4 lg:hidden'>
        <div className='flex items-center justify-between'>
          <GoBackIcon
            onClick={() => closeHandler(false)}
            className='has-color fill-current text-blue'
          />
          <h1 className='line-clamp-1 px-3 text-xl capitalize'>
            {t?.('store_results')} ({list.length})
          </h1>
          <div></div>
        </div>
      </div>
      <div className='relative flex-1'>{children}</div>
    </div>
  );
}
