'use client';
import {
  GoogleMap,
  InfoBoxF,
  InfoWindow,
  MarkerF,
  useLoadScript,
} from '@react-google-maps/api';
import React, { useContext, useRef } from 'react';
import { IMarkerData, StoreLocatorMapContext } from './pharmacy.context';
import { silverMapStyle } from 'components/shipping-method-switcher/maps/utils';
import { Button } from 'components/buttons/button';
import { MapInfoCloseIcon } from 'components/icon';
import StoreDetailCard from './card';
import { INearByStore } from 'types/shipping-method-switcher';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { gtmSetApiCall } from 'lib/gtm';
const libs = ['places'];
export default function MapUI({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const {
    t,
    setMap,
    containerStyle,
    cordinate,
    stores,
    activeMarker,
    setActiveMarker,
    selectedStore,
    setSelectedStore,
    activeCategoryFilter,
  } = useContext(StoreLocatorMapContext);

  const mapRef = useRef<google.maps.Map | null>(null);
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY!,
    libraries: libs as never,
    language: language,
  });

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      gtmSetApiCall(map);
      mapRef.current = map;
      setMap?.(map);
    },
    [setMap]
  );

  const onUnmount = React.useCallback(
    function callback() {
      setMap?.(null);
    },
    [setMap]
  );

  const list = activeCategoryFilter
    ? stores.filter(
        (store) =>
          (store as unknown as Record<string, unknown>)?.[
            activeCategoryFilter
          ] == true
      )
    : stores;

  const markers = [
    ...list.map((nearByStore, i) => {
      return {
        draggable: false,
        position: {
          lat: nearByStore.latitude,
          lng: nearByStore.longitude,
        },
        markerIcon: nearByStore.is_open
          ? '/assets/images/available-store.svg'
          : '/assets/images/non-available-store.svg',
        size:
          activeMarker?.lat() == nearByStore.latitude &&
          activeMarker?.lng() == nearByStore.longitude
            ? new google.maps.Size(47.036, 59.381, 'px', 'px')
            : i < 4
            ? new google.maps.Size(37.036, 49.381, 'px', 'px')
            : new google.maps.Size(17.79, 23.71, 'px', 'px'),
        store: nearByStore,
      } as IMarkerData & { size: google.maps.Size };
    }),
  ];

  return (
    <div className='h-full'>
      <style>
        {`
          .gm-style-iw-ch {
            padding-top: 0px !important;
          }
          .gm-style .gm-style-iw-c {
            border-radius: 50px;
          }

          .gm-ui-hover-effect {
            display: none !important;
          }
          .gm-style-iw, .gm-style-iw  * {
            background-color: white !important;
            overflow: hidden
          }
          .gm-style-iw-d{
            overflow:hidden !important;
          }
          .gm-style .gm-style-iw-tc::after{
            background-color: #D4232D !important;
          }
          .gm-style .gm-style-iw-tc::before {
            background: #fff;
            -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            content: "";
            height: 12px;
            left: 0;
            position: absolute;
            top: -3.1px;
            width: 25px;
            z-index: 1;
          }
          .gm-style-iw-c {
            border: 1px solid #D4232D;
            padding-right: 0 !important;
            padding-left: 0 !important;
            padding:0 !important;
          }
          .gm-style-iw[aria-label="${t?.('nearest')}"] {
            padding-top: 0 !important;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 9px;
            border: 2px solid #2C6BA4;
          }
          .gm-style-iw[aria-label="${t?.(
            'nearest'
          )}"] + .gm-style-iw-tc::after {
            background-color: #2C6BA4 !important;
          }
        `}
      </style>

      {isLoaded ? (
        <GoogleMap
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            styles: silverMapStyle,
            disableDefaultUI: true,
          }}
          center={activeMarker ?? cordinate}
          zoom={+(process.env.NEXT_PUBLIC_GOOGLE_MAP_DEFAULT_ZOOM ?? 14)}
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {markers.map(
            ({ position, draggable, markerIcon, store, size }, i) => (
              <MarkerF
                key={`${store?.latitude} ${store?.latitude} ${i}`}
                draggable={draggable}
                position={position}
                clickable={true}
                onClick={(e) => {
                  if (!store) return;
                  setActiveMarker?.(e.latLng);
                  selectedStore?.shop_id === store?.shop_id
                    ? setSelectedStore?.(undefined)
                    : setSelectedStore?.(store);
                }}
                options={{
                  icon: {
                    url: markerIcon as string,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(25, 50),
                    scaledSize: size,
                  },
                }}
                opacity={
                  store
                    ? setSelectedStore !== undefined
                      ? selectedStore &&
                        selectedStore?.shop_id !== store?.shop_id
                        ? 0.2
                        : 1
                      : 1
                    : 1
                }
              >
                {activeMarker &&
                  selectedStore &&
                  selectedStore.shop_id === store?.shop_id && (
                    <InfoBoxF
                      options={{
                        position: activeMarker,
                        disableAutoPan: false,
                        maxWidth: 0,
                        pixelOffset: new google.maps.Size(-185, 5),
                        boxStyle: {
                          padding: '0px 0px 0px 0px',
                          width: '369px',
                        },
                        closeBoxURL: 'font-montserrat',
                        boxClass: '!font-sans',
                        infoBoxClearance: new google.maps.Size(1, 1),
                        isHidden: false,
                        pane: 'floatPane',
                        enableEventPropagation: false,
                      }}
                    >
                      <MapInfo
                        setActiveMarker={setActiveMarker}
                        setSelectedStore={setSelectedStore}
                        t={t}
                        selectedStore={selectedStore}
                        language={language}
                        region={region}
                      />
                    </InfoBoxF>
                  )}
              </MarkerF>
            )
          )}
          {!selectedStore &&
            markers &&
            markers.length > 0 &&
            markers[0]?.position?.lat &&
            markers[0]?.position?.lng && (
              <InfoWindow
                position={markers[0].position}
                options={{
                  pixelOffset: new window.google.maps.Size(-9.4, -50),
                  ariaLabel: t?.('nearest'),
                }}
              >
                <h3 className='flex items-center justify-center p-1 px-3 font-semibold text-blue ltr:font-montserrat rtl:font-tajawal'>
                  {t?.('nearest')}
                </h3>
              </InfoWindow>
            )}
        </GoogleMap>
      ) : (
        <p>loading...</p>
      )}
    </div>
  );
}
function MapInfo({
  language,
  region,
  setActiveMarker,
  selectedStore,
  t,
  setSelectedStore,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
  setActiveMarker:
    | ((activeMarker: google.maps.LatLng | null) => void)
    | undefined;
  setSelectedStore:
    | React.Dispatch<React.SetStateAction<INearByStore | undefined>>
    | undefined;
  selectedStore: INearByStore;
  // eslint-disable-next-line @typescript-eslint/ban-types
  t?: Function;
}) {
  return (
    <div className='relative mt-4 flex font-montserrat'>
      <div className='absolute -top-[12px] left-1/2 z-50 h-0 w-0 -translate-x-1/2 rotate-180 transform rounded border-l-[12px] border-r-[12px] border-t-[12px] border-l-transparent border-r-transparent border-t-white shadow-xl'></div>
      <div className='relative flex max-w-[369px] flex-col justify-between gap-4 rounded-lg bg-white p-4 shadow-md'>
        <MapInfoCloseIcon
          className='absolute end-4 top-4 h-6 w-6 cursor-pointer text-gray-dark'
          onClick={(e: Event) => {
            setActiveMarker?.(null);
            setSelectedStore?.(undefined);
            e?.stopPropagation();
          }}
        />
        <div className='max-w-[90%]'>
          <StoreDetailCard
            t={t}
            storeItem={selectedStore}
            index={1}
            language={language}
            region={region}
          />
        </div>

        <div className='mt-4 flex w-full items-end lg:mt-0'>
          <Button
            onClick={() => window.open(selectedStore.google_maps_url, '_blank')}
            className='h-10 py-0 !text-custom-xs ltr:font-montserrat rtl:font-tajawal'
          >
            {t?.('get_directions')}
          </Button>
        </div>
      </div>
    </div>
  );
}
