'use client';

import { Dispatch, SetStateAction, createContext } from 'react';
import { INearByStore } from 'types/shipping-method-switcher';
import { MarkerProps } from '@react-google-maps/api';
import { DEFAULT_GEO } from 'components/shipping-method-switcher/maps/utils';
import { CountryType } from 'types/country';
import { FinderMode } from '../enum';

export interface IMapCordinate {
  lat: number;
  lng: number;
}

export interface IMarkerData {
  position: IMapCordinate;
  draggable: boolean;
  markerIcon?: MarkerProps['icon'];
  store?: INearByStore;
}

export type containerStyle = React.CSSProperties;

export interface IMapStoreLocatorContext {
  setZoom?: (level: number) => void;
  setCenter?: Dispatch<SetStateAction<IMapCordinate>>;
  markers: IMarkerData[];
  cities: CountryType[];
  setCities?: Dispatch<SetStateAction<CountryType[]>>;
  setMarkers?: Dispatch<SetStateAction<IMarkerData[]>>;
  setMapContainer?: (style: containerStyle) => void;
  containerStyle: containerStyle;
  map: google.maps.Map | null;
  setMap?: Dispatch<SetStateAction<google.maps.Map | null>>;
  cordinate: IMapCordinate;
  setCordinate?: Dispatch<SetStateAction<IMapCordinate>>;
  updateWithLatLng?: (lat: number, lng: number, map?: google.maps.Map) => void;
  isUnknownLocation: boolean;
  setIsUnknownLocation?: Dispatch<SetStateAction<boolean>>;
  selectedStore?: INearByStore | undefined;
  setSelectedStore?: Dispatch<SetStateAction<INearByStore | undefined>>;

  activeMarker?: google.maps.LatLng | null;
  setActiveMarker?: (activeMarker: google.maps.LatLng | null) => void;
  t?: Function; // eslint-disable-line @typescript-eslint/ban-types
  ts?: Function; // eslint-disable-line @typescript-eslint/ban-types
  fetchPharmacyByDistricts?: (districtId: string) => Promise<void>;
  stores: INearByStore[];
  fetchByCordinate?: (
    lat: number,
    lng: number,
    radius?: string
  ) => Promise<void>;
  isListView: boolean;
  setIsListView?: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  mapIsOpen: boolean;
  mode: FinderMode;
  setMapIsOpen?: Dispatch<SetStateAction<boolean>>;
  categories: { key: string; value: { en: string; ar: string } }[];
  activeCategoryFilter?: string;
  setActiveCategoryFilter?: Dispatch<SetStateAction<string | undefined>>;
  rangeOptions: number[];
}

const initialState: IMapStoreLocatorContext = {
  markers: [],
  map: null,
  containerStyle: {
    width: '100%',
    height: '100%',
  },
  cities: [],
  activeMarker: undefined,
  cordinate: DEFAULT_GEO,
  isUnknownLocation: false,
  stores: [],
  isListView: false,
  isLoading: false,
  mapIsOpen: false,
  categories: [],
  activeCategoryFilter: undefined,
  mode: FinderMode.INITIAL,
  rangeOptions: [],
};

export const StoreLocatorMapContext =
  createContext<IMapStoreLocatorContext>(initialState);
