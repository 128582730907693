import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { ItemAvailableType } from 'types/available';

export default function Available(props: ItemAvailableType) {
  const { backgroundColor, children } = props;
  return (
    <div
      className={twMerge(
        clsx(
          `flex items-center rounded-2xl ${
            backgroundColor ? backgroundColor : 'bg-red/20'
          }`,
          'space-x-2 px-3 py-1 rtl:space-x-reverse'
        )
      )}
    >
      {children}
    </div>
  );
}
